import React from 'react'
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ImgHeader from "../components/ImgHeader"
import CardListing from '../components/cards/CardListing';
import Events from "../components/engage/Events"

import TitleDiv from "../components/elements/TitleDiv"
import VHCenter from '../components/elements/VHCenter'
import Title from '../components/elements/Title'
import image from "../assets/Engage-Us.jpg"
import EventsListing from '../components/engage/EventsListing'

const Engage = ({ data }) => {
  const { title } = data.ImgHeaderQuery
  const background = data.ImgHeaderQuery.backgroundImg.fluid.src
  const firstTitle = data.TitleDivQuery.edges[0].node.header
  const secondTitle = data.TitleDivQuery.edges[1].node.header

  return (
    <Layout>
      <SEO title="Engage" />
      <ImgHeader image={background} title={title} />
      <TitleDiv color="#feb600" style={{ marginTop: "0%" }}>
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: firstTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div className="container" style={{ marginTop: "5%", marginBottom: "1%" }}>
        <CardListing path="/engage" btn={true} />
      </div>
      <div id="events">
        <TitleDiv color="#85b8cd">
          <TitleDiv color="#85b8cd">
            <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: secondTitle }}></div></Title></div></VHCenter>
          </TitleDiv>
        </TitleDiv>
      </div>
      <EventsListing />

    </Layout>
  )
}

export default Engage

export const query = graphql`
    query EngagePageQuery {
        ImgHeaderQuery: contentfulImgHeader(path: { eq: "/engage" }) {
            title
            path
            backgroundImg {
              fluid(quality: 100) {
                src
              }
            }
        }
        TitleDivQuery: allContentfulTitleDiv(
          filter: { path: { eq: "/engage" }}, 
          sort: { fields: orderId }
          )
        {
          edges {
            node {
              orderId
              header 
            }
          }
        }
    }
`
