import React, { useEffect } from 'react'
import { Link } from "gatsby"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import styled from "styled-components"
import Modal from "./Modal"
import Therapy from "../../assets/individuals/therapy.png"
import VHCenter from "../elements/VHCenter"
import '../discoverUs/section.css'
// import "./cards.css"

const Div = styled.div`
  /* background-color: blue; */
  height: ${(props) => props.height};
  @media (max-width: 768px) {
    height: ${(props) => props.smHeight};
  }
`

const TextDiv = styled.div`
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
border-radius: 0px 0px 4px 4px;
background-color: #626363;
/* @media (max-width: 767px) {
     width: 80%;
     height: 200px;
     background-color:yellow;
  } */

`;

const ImgDiv = styled.div`
background-color: #85b8cd;
margin: auto;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
border-radius: 5px 5px 0px 0px;
/* @media (max-width: 767px) {
     width: 80%;
     height: 250px;
     margin: auto;
     background-color:yellow;
     } */
`;
const Box = styled.div`
width: 200px;
height: 200px; 
margin: auto;
/* @media (max-width: 767px) {
     width: 100px;
     height: 300px;
     background-color:blue;
     } */
`
const Card = ({ cardId, title, titleColor, path, btn, img, width, height, body, modalbody, textLeft }) => {




  //  Adding # and removing spaces from title to create an id for data-target of modal
  // Removing : from title to create an id
  // Removing number 1 from title to create an id

  var id = "#" + cardId
  id = id.replace(/\s/g, '');
  id = id.replace(/:/g, '');
  id = id.replace(/1/g, '');

  var trimByWord = (body) => {
    var result = body;
    var resultArray = result.split(" ");
    if (resultArray.length > numberOfWords) {
      resultArray = resultArray.slice(0, numberOfWords);
      result = resultArray.join(" ") + " ...";
    }
    return result;
  }

  var maxWordCount = 30
  var numberOfWords

  return (

    <div className="col-sm-12 col-md-6 col-lg-4" style={{ marginBottom: "5%" }}>
      <ImgDiv className="container">
        <Box className="">

          <VHCenter className="center">

            {
              path == "/corporates" ?
                <img className="" width="260px" height="200px" src={img} alt={title} />
                :
                ""
            }

            {
              path == "/corporates/ourService" ?
                <img width={width} height={height} src={img} alt="Sheild" />
                :
                ""
            }
            {
              path == "/engage" ?
                <img width={width} height={height} src={img} alt="Sheild" />
                :
                ""
            }
            {
              path == "/individuals" ?
                <img width={width || "100px"} height={height || "100px"} src={img} alt="Sheild" />
                :
                ""
            }
            {
              path == "/kids" ?
                <img width={width} height={height} src={img} alt="Sheild" />
                :
                ""
            }
          </VHCenter>
        </Box>
      </ImgDiv>
      <TextDiv className="container" style={{ backgroundColor: "#626363" }}>
        <div className="custom-card">
          {
            path == "/corporates" ?
              <Div className="" height="30px" ><h5 style={{ color: titleColor || "white", textAlign: "left", fontFamily: "avenir-black" }}>{title}</h5></Div>
              :
              <Div className="" height="55px" ><h5 className="" style={{ color: titleColor || "white", textAlign: "left", fontFamily: "avenir-black" }}>{title}</h5></Div>
          }
          {/* <Div className="" height="55px" ><h5 style={{ color: titleColor || "white", textAlign: "left", fontFamily: "avenir-black" }}>{title}</h5></Div> */}


          <Div className="" height="150px" smHeight="100px" style={{ color: "white", textAlign: "justify", fontFamily: "avenir-medium" }}>
            {
              <div dangerouslySetInnerHTML={{ __html: trimByWord(body) }}></div>

            }
          </Div>
          <div style={{ textAlign: "right", }}>
            {
              path == "/corporates" && btn == false ?
                ""
                :
                <a href="" className="avenir-heavy" style={{ color: "#85b8cd" }} data-toggle="modal" data-target={id} >LEARN MORE</a>
            }
          </div>
        </div>
        {
          path == "/corporates" ?
            ""
            :
            <Modal cardId={cardId} modalbody={modalbody} title={title} img={img} width={width} height={height} />
        }
      </TextDiv>
    </div>

  )
}
export default Card
