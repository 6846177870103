import React from "react"
import styled from "styled-components"
import VHCenter from "../elements/VHCenter"

const Div = styled.div`
  width: 280px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: ${props => props.color};
  margin: auto;
/*    @media (max-width: 768px) {
     width: 280px;
     height: 215px;
     margin: auto; 
 }*/
`

const Modal = ({ cardId, title, img, width, height, modalbody }) => {
    // Removing space from title and converting it into id for modal
    // Removing : from title to create an id
    // Removing number 1 from title to create an id
    // var id = title.replace(/\s/g, '');
    // id = id.replace(/:/g, '');
    // id = id.replace(/1/g, '');
    // id=id.replace(/:/g,'');

    return (
        <div>
            <div className="modal fade" id={cardId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content" >
                        <div className="m-3">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ color: "black", opacity: "0.5" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ width: "90%", margin: "auto" }}>
                            <div className="center">
                                <Div className="text-center container" color="#85b8cd">
                                    <VHCenter>
                                        {/* <img width="250px" height="170px" src={img} alt="mind fitness" /> */}
                                        <img width={width} height={height} src={img} alt="mind fitness" />
                                    </VHCenter>
                                </Div>
                            </div>
                            <h4 className="text-center" style={{ color: "#85b8cd", marginTop: "5%" }}>{title}</h4>
                            <div className="avenir-roman text-justify" dangerouslySetInnerHTML={{ __html: modalbody }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal

