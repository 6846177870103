import React from 'react'
import "./events.css"
import calendar from "../../assets/calendar.png"

const Events = ({ td2, td3, buttonText, date, eventName, time, city }) => {
    return (
        <tr>
            <td>
                <div>
                    <img src={calendar} />
                    <span>{date}</span>
                </div>
            </td>
            <td data-label="Event & Location" className="container">
                <b><div dangerouslySetInnerHTML={{ __html: eventName }}></div></b>
                <div dangerouslySetInnerHTML={{ __html: time }}></div>
                <div dangerouslySetInnerHTML={{ __html: city }}></div>
            </td>
            <td className="avenir-medium" data-label="Description">
                <span dangerouslySetInnerHTML={{ __html: td2 }}></span>
            </td>
            <td className="avenir-medium" data-label="Cost">
                <span dangerouslySetInnerHTML={{ __html: td3 }}></span>            </td>
            <td className="avenir-black">
                <button>{buttonText}</button>
            </td>
        </tr>
    )
}

export default Events
