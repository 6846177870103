import React from 'react'
import { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import Events from './Events'
import "./events.css"
// import Calendar from './Calendar'

const EventsListing = ({ }) => {

  const data = useStaticQuery(graphql`
    query {
      events: allContentfulEvents(
        filter: { path: { eq: "/engage" } }
        sort: { fields: [date], order: DESC }
      ) {
        edges {
          node {
            date(formatString:"D")
            eventName
				    time
				    city
            eventOrder
            buttonText
            td2 {
              childContentfulRichText {
                html
              }
            }
            td3 {
              childContentfulRichText {
                html
              }
            }
            icon {
              fluid {
                src
              }
            }
          }
        }
      }
        tableHeader: allContentfulEventHeader(filter: { path: { eq: "/engage" } }) {
            edges {
              node {
                th1
                th2
                th3
              }
            }
          }
      }
    
    `)

  return (
    <div>
      {/* <Calendar /> */}
      <div id="thf-events" className="container ">
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">{data.tableHeader.edges[0].node.th1}</th>
              <th scope="col">{data.tableHeader.edges[0].node.th2}</th>
              <th scope="col">{data.tableHeader.edges[0].node.th3}</th>
            </tr>
          </thead>
          <tbody>
            {
              data.events.edges.map((edge) => {
                return (
                  <Events
                    key={edge.node.eventOrder}
                    eventName={edge.node.eventName}
                    time={edge.node.time}
                    city={edge.node.city}
                    td2={edge.node.td2.childContentfulRichText.html}
                    td3={edge.node.td3.childContentfulRichText.html}
                    buttonText={edge.node.buttonText}
                    date={edge.node.date}
                  />
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EventsListing
