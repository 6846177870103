import React from 'react'
import Card from './Card'

import { graphql, useStaticQuery } from "gatsby";

const CardListing = ({ path, btn }) => {
  const data = useStaticQuery(graphql`
        query {
          individuals: allContentfulCard(filter: {path: {eq: "/individuals"}}, sort: {fields: orderId}) {
            edges {
              node {
                orderId
                title,
                width,
                height,
                cardId
                img{
                  fluid{
                    src
                  }
                }
                body {
                  childContentfulRichText {
                    html
                  }
                }
                modalbody {
                  childContentfulRichText {
                    html
                  }
                }  
              }
            }
          }
          corporates: allContentfulCard(filter: {path: {eq: "/corporates"}}, sort: {fields: orderId}) {
            edges {
              node {
                orderId
                title,
                titleColor,
                width,
                cardId
                img{
                  fluid{
                    src
                  }
                }
                body {
                  childContentfulRichText {
                    html
                  }
                } 
              }
            }
          }
          ourService: allContentfulCard(filter: {path: {eq: "/corporates/ourService"}}, sort: {fields: orderId}) {
            edges {
              node {
                orderId
                title,
                titleColor,
                width,
                height,
                cardId
                img{
                  fluid{
                    src
                  }
                }
                body {
                  childContentfulRichText {
                    html
                  }
                }
                modalbody {
                  childContentfulRichText {
                    html
                  }
                }  
              }
            }
          }
          engage: allContentfulCard(filter: {path: {eq: "/engage"}},sort: {fields: orderId} ) {
            edges {
              node {
                orderId
                title,
                titleColor,
                width,
                height
                cardId
                img{
                  fluid{
                    src
                  }
                }
                body {
                  childContentfulRichText {
                    html
                  }
                } 
                modalbody {
                  childContentfulRichText {
                    html
                  }
                }
              }
            }
          }
          kids: allContentfulCard(filter: {path: {eq: "/kids"}}, sort: {fields: orderId}) {
            edges {
              node {
                orderId
                title,
                titleColor,
                width,
                height
                cardId
                img{
                  fluid{
                    src
                  }
                }
                body {
                  childContentfulRichText {
                    html
                  }
                }
                modalbody {
                  childContentfulRichText {
                    html
                  }
                }
              }
            }
          }
        }
    `)

  return (
    <div>
      <div className="row">
        {
          path == "/individuals" ?
            data.individuals.edges.map((edge) => {
              return (
                <Card cardId={edge.node.cardId} key={edge.node.orderId} path="/individuals" modalbody={edge.node.modalbody.childContentfulRichText.html} body={edge.node.body.childContentfulRichText.html} width={edge.node.width} height={edge.node.height} img={edge.node.img.fluid.src} title={edge.node.title} />
              )
            })
            :
            ""
        }
      </div>
      <div className="row">
        {
          path == "/corporates" ?
            data.corporates.edges.map((edge) => {
              return (
                <Card cardId={edge.node.cardId} key={edge.node.orderId} path="/corporates" textLeft="left" body={edge.node.body.childContentfulRichText.html} width={edge.node.width} img={edge.node.img.fluid.src} btn={btn} titleColor={edge.node.titleColor} title={edge.node.title} />
              )
            })
            :
            ""
        }
      </div>
      <div className="row">
        {
          path == "/corporates/ourService" ?
            data.ourService.edges.map((edge) => {
              return (
                <Card cardId={edge.node.cardId} key={edge.node.orderId} path="/corporates/ourService" modalbody={edge.node.modalbody.childContentfulRichText.html} body={edge.node.body.childContentfulRichText.html} width={edge.node.width} height={edge.node.height} img={edge.node.img.fluid.src} btn={btn} titleColor={edge.node.titleColor} title={edge.node.title} />
              )
            })
            :
            ""
        }
      </div>
      <div className="row">
        {
          path == "/engage" ?
            data.engage.edges.map((edge) => {
              return (
                <Card cardId={edge.node.cardId} key={edge.node.orderId} path="/engage" modalbody={edge.node.modalbody.childContentfulRichText.html} body={edge.node.body.childContentfulRichText.html} img={edge.node.img.fluid.src} width={edge.node.width} height={edge.node.height} btn={btn} titleColor={edge.node.titleColor} title={edge.node.title} />
              )
            })
            :
            ""
        }
      </div>
      <div className="row">
        {
          path == "/kids" ?
            data.kids.edges.map((edge) => {
              return (
                <Card cardId={edge.node.cardId} key={edge.node.orderId} path="/kids" modalbody={edge.node.modalbody.childContentfulRichText.html} body={edge.node.body.childContentfulRichText.html} width={edge.node.width} height={edge.node.height} img={edge.node.img.fluid.src} title={edge.node.title} />
              )
            })
            :
            ""
        }
      </div>
    </div>
  )
}

export default CardListing
